.altai-theme-admin .contentypeletterpill {
  display: inline-block;
  border-radius: 12px;
  padding: 4px 10px 4px 10px;
  color: #f9f9f9;
  background-color: #303030;
}
.altai-theme-admin .commentpill {
  display: inline-block;
  min-width: 45px;
  text-align: center;
  border-radius: 12px;
  padding: 4px 10px 4px 10px;
  color: #3f3f3f;
  background-color: #00ffcb;
}
.altai-theme-admin .pill-complexdossier {
  background-color: #8200a8;
}
.altai-theme-admin .pill-actumessage {
  background-color: #160085;
}
.altai-theme-admin .pill-pressrelease {
  background-color: #a00027;
}
